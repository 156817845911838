<template>
  <v-container :class="`pa-8`">
    <v-row class="mb-4">
      <h2>
        {{ $t('settings.preferences') }}
      </h2>
    </v-row>
    <v-row>
      <v-chip
        v-for="tag in tags"
        :key="tag.id"
        @click="onTagClick(tag.id)"
        :color="tagIds.includes(tag.id) ? 'primary' : ''"
        style="margin: 3px;"
      >
        {{
          $t(`onboarding.preferences.tags.${tag.name.replace(/ /g, '')}`) ||
          tag.name
        }}
      </v-chip>   
    </v-row>
    <v-row justify="end">
      <v-btn
        :color="'primary'"
        class="hs-rounded-12"
        :disabled="shouldDisable"
        @click="submit"
      >
        {{ $t('save') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import PreferenceService from '@api/preference'
  import TagService from '@api/tag'

  export default {

    async created() {
      this.preferenceService = PreferenceService(this.user.id)
      this.tagService = TagService(this.user.id)

      this.tags = await this.tagService.list()

      const { listPreferenceTags } = this.preferenceService
      const preferenceTags = await listPreferenceTags(this.profileId)
      
      this.tagIds = preferenceTags.map(preferenceTag => preferenceTag.tag)
      this.initialTagIds = this.tagIds
    },

    data: () => ({
      preferenceService: null,
      tagService: null,
      tags: [],
      initialTagIds: [],
      tagIds: [],
    }),

    methods: {
      onTagClick(tagId) {
        if (this.tagIds.includes(tagId)) {
          this.tagIds = this.tagIds.filter(id => id !== tagId)
        } else {
          this.tagIds = [...new Set([...this.tagIds, tagId])];
        }
      },

      async submit() {
        await this.preferenceService.updatePreferenceTags({
          profileId: this.profileId,
          tagIds: this.tagIds
        })
        this.initialTagIds = this.tagIds
      }
    },

    computed: {
      ...mapGetters({
        user: 'user',
        profileId: 'profile/id'
      }),

      shouldDisable() {
        return (
          this.tagIds.length === 0 ||
          this.tagIds.sort().join(',') === this.initialTagIds.sort().join(',')
        )
      }
    }
  }
</script>