import API from '@api';

export default (userId) => {
  const apiRequest = API()
  return {
    listPreferenceTags (profileId) {
      return apiRequest.get(
        `preference-item-tags/${userId}`,
        { params: { profileId } }
      )
    },

    updatePreferenceTags (body) {
      return apiRequest.post(
        `preference-item-tags/${userId}`,
        body
      )
    }
  }
}
