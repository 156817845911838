<template>
  <div>
    <SnackBar
      :snackMsg="snackMsg"
      :snackOn="snackOn"
      :snackTop="true"
      @close="snackOn = false"
    />

    <ChangeFullName
      :dialogOn="dialogFullName"
      :currentFullName="user.fullName"
      @close="dialogFullName = false"
      @snackEvt="setSnack"
    />

    <ChangeEmail
      :dialogOn="dialogEmail"
      :email="user.email"
      :fullName="user.fullName"
      @close="dialogEmail = false"
      @snackEvt="setSnack"
    />
    
    <ChangePassword
      :dialogOn="dialogPwd"
      @close="dialogPwd = false"
      @snackEvt="setSnack"
    />

    <DeactivateAccount
      :dialogOn="dialogActivation"
      @close="dialogActivation = false"
      @snackEvt="setSnack"
    />

    <v-container class="py-0">
      <v-row justify="center">
        <v-col
          xl="6"
          lg="8"
        >
          <v-card
            outlined
            class="hs-rounded-xl"
          >
            <v-container>
              <v-list-item
                class="px-3"
                two-line
              >
                <v-list-item-avatar size="120">
                  <v-img
                    v-if="avatar || loading"
                    :src="getResourceUrl(avatar)"
                  >
                    <template v-slot:placeholder>
                      <v-skeleton-loader
                        class="mx-auto"
                        height="120"
                        width="120"
                        type="avatar"
                      />
                    </template>
                  </v-img>
                  <v-icon
                    style="font-size: 130px;"
                    v-else
                  >
                    {{ mdiAccountCircle }}
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title 
                    class="headline"
                    v-text="name"
                  />
                  <v-list-item-subtitle
                    class="subtitle-1"
                    v-text="bio" 
                  />
                  <v-list-item-subtitle
                    v-if="city && country"
                    v-text="`${city}, ${country}`"
                  />
                </v-list-item-content>
              </v-list-item>

              <v-divider />

              <v-container>
                <v-list-item class="px-1">
                  <v-list-item-content>
                    <v-list-item-subtitle v-text="$t('settings.fullName')" />
                    <v-list-item-title 
                      v-text="user.fullName"
                    />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      color="primary"
                      style="border-radius: 8px;"
                      v-text="$t('edit')"
                      @click="dialogFullName = !dialogFullName"
                    />
                  </v-list-item-action>
                </v-list-item>

                <v-list-item class="px-1">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Primary e-mail
                    </v-list-item-subtitle>

                    <v-list-item-title 
                      v-text="user.email"
                    />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      color="primary"
                      style="border-radius: 8px;"
                      v-text="$t('edit')"
                      @click="dialogEmail = !dialogEmail"
                    />
                  </v-list-item-action>
                </v-list-item>

                <v-list-item
                  v-if="user.oauth ? user.oauth.email : false"
                  class="px-1"
                >
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      Gmail (Google Login)
                    </v-list-item-subtitle>
                    
                    <v-list-item-title 
                      v-text="user.oauth.email"
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-container>

              <v-divider class="mb-2" />

              <v-card-actions>
                <v-btn
                  text
                  color="primary"
                  class="hs-rounded-12"
                  @click="dialogPwd = true"
                >
                  {{ $t('settings.changePwd') }}
                  <v-icon right>
                    {{ mdiLock }}
                  </v-icon>
                </v-btn>

                <v-spacer />

                <v-btn
                  text
                  color="grey darken-2"
                  class="hs-rounded-12"
                  @click="setAccountActivation"
                >
                  {{ message }}
                </v-btn>
              </v-card-actions> 
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          xl="6"
          lg="8"
        >
          <v-card
            outlined
            class="hs-rounded-xl"
          >
            <Preferences />
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          xl="6"
          lg="8"
        >
          <v-card
            outlined
            class="hs-rounded-xl"
          >
            <v-container>
              <v-container>
                <v-select
                  :label="$t('settings.language')"
                  outlined
                  hide-details
                  :items="locales"
                  item-value="code"
                  item-text="name"
                  :append-icon="mdiTranslate"
                  style="border-radius: 12px"
                  v-model="locale"
                />
              </v-container>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          xl="6"
          lg="8"
        >
          <v-card
            outlined
            class="hs-rounded-xl"
          >
            <v-container>
              <v-container>
                <v-list>
                  <v-list-item class="px-1">
                    <v-list-item-content>
                      <v-list-item-title 
                        class="text-h5 font-weight-light"
                        v-text="$t('settings.darkThemeTitle')"
                      />
                      <v-list-item-subtitle
                        class="grey--text text--darken-1"
                        v-text="$t('settings.darkThemeSubtitle')"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="$vuetify.theme.dark"
                        color="primary"
                        inset
                      />
                    </v-list-item-action>
                  </v-list-item>
                  
                  <v-list-item
                    v-for="(config, idx) in configs"
                    :key="`config_${idx}`"
                    class="px-1"
                  >
                    <v-list-item-content>
                      <v-list-item-title 
                        class="text-h5 font-weight-light"
                        v-text="$t(`settings.${config.title}`)"
                      />
                      <v-list-item-subtitle
                        class="grey--text text--darken-1"
                        v-text="$t(`settings.${config.subtitle}`)"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        v-model="config.value"
                        color="primary"
                        inset
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-container>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DeactivateAccount from '@components/Settings/DeactivateAccount'
import ChangePassword from '@components/Settings/ChangePassword'
import ChangeFullName from '@components/Settings/ChangeFullName'
import ChangeEmail from '@components/Settings/ChangeEmail'
import Preferences from '@components/Settings/Preferences'
import { SnackBar } from '@components/App'
import { mapGetters, mapMutations } from 'vuex'
import { getResourceUrl } from '@utils'
import API from '@api'
import {
  mdiLock,
  mdiTranslate,
  mdiAccountCircle
} from '@mdi/js'

export default {
  
  components: {
    DeactivateAccount,
    ChangePassword,
    ChangeFullName,
    ChangeEmail,
    SnackBar,
    Preferences
  },

  created() {
    this.name = this.profileName
    this.bio = this.profileBio
    this.avatar = this.profileAvatar
    
    this.locale = this.settings.locale === 'pt-br'
      ? { name: 'Português', code: 'pt-br' }
      : { name: 'English', code: 'en' }

    // TODO: remove group invitations
    this.configs[0].value = this.settings.tooltipsOn
    this.configs[1].value = this.settings.publicMailOn
    this.configs[2].value = this.settings.friendshipRequestsOn
    this.configs[3].value = this.settings.groupInvitationsOn
    this.configs[4].value = this.settings.eventInvitationsOn

    this.loading = false
  },

  data() {
    return {
      mdiLock,
      mdiTranslate,
      mdiAccountCircle,

      snackMsg: '',
      snackOn: false,

      dialogActivation: false,
      dialogFullName: false,
      dialogEmail: false,
      dialogPwd: false,
      loading: true,
      locale: {
        name: 'Português',
        code: 'pt-br'
      },
      locales: [
        {
          name: 'English',
          code: 'en'
        },
        {
          name: 'Português',
          code: 'pt-br'
        }
      ],
      name: '',
      bio: '',
      avatar: '',
      options: [
        {
          title: 'Alterar nome completo',
          firstLabel: 'Nome completo',
          secondLabl: 'Senha atual'
        },
        {
          title: 'Alterar seu e-mail',
          firstLabel: 'Novo e-mail',
          secondLabl: 'Senha atual'
        },
        {
          title: 'Alterar sua senha',
          firstLabel: 'Nova Senha',
          secondLabl: 'Senha atual'
        }
      ],
      configs: [
        {
          title: 'tooltipsTitle',
          subtitle: 'tooltipsSubtitle',
          value: true
        },
        {
          title: 'publicMailTitle',
          subtitle: 'publicMailSubtitle',
          value: true
        },
        {
          title: 'friendshipRequestTitle',
          subtitle: 'friendshipRequestSubtitle',
          value: true
        },
        {
          title: 'groupInvitationsTitle',
          subtitle: 'groupInvitationsSubtitle',
          value: true
        },
        {
          title: 'eventInvitationsTitle',
          subtitle: 'eventInvitationsSubtitle',
          value: true
        }
      ]
    }
  },

  watch: {
    locale: {
      deep: true,
      handler: function(v) {
        const value = typeof v === 'object' ? v.code : v

        if(!this.loading) {
          this.setLocale(value)
          this.setSettings({ locale: value })
          
          API().put(`/profile/${this.user.id}`, {
            'settings.locale': value
          }, {
            params: {
              id: this.profile
            }
          })
        }
      }
    },

    configs: {
      deep: true,
      handler: function(v) {
        if (!this.loading) {
          this.setSettings({
            tooltipsOn: v[0].value,
            publicMailOn: v[1].value,
            friendshipRequestsOn: v[2].value,
            groupInvitationsOn: v[3].value,
            eventInvitationsOn: v[4].value
          })

          // TODO: remove group invitations
          API().put(`/profile/${this.user.id}`, {
            'settings.tooltipsOn': v[0].value,
            'settings.publicMailOn': v[1].value,
            'settings.friendshipRequestsOn': v[2].value,
            'settings.groupInvitationsOn': v[3].value,
            'settings.eventInvitationsOn': v[4].value
          }, {
            params: {
              id: this.profile
            }
          })  
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      isActive: 'profile/isActive',
      profile: 'profile/id',
      profileName: 'profile/name',
      profileBio: 'profile/bio',
      profileAvatar: 'profile/avatar',
      settings: 'settings',
      user: 'user'
    }),

    message() {
      return this.isActive ? 'Deactivate account' : 'Activate account'
    }
  },

  methods: {
    ...mapMutations({
      setLocale: 'locale',
      setSettings: 'settings',
      SET_ACCOUNT_ACTIVATION: 'profile/isActive'
    }),

    getResourceUrl,

    async setAccountActivation() {
      if (this.isActive) {
        this.dialogActivation = true

      } else {
        this.SET_ACCOUNT_ACTIVATION(true)

        await API().put(`/profile/${this.user.id}`, {
          isActive: true
        }, {
          params: {
            id: this.profile
          }
        })
      }
    },

    setSnack (snackMsg) {
      this.snackMsg = snackMsg
      this.snackOn = true
    }
  }
}
</script>