<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    @click:outside="clear"
  >
    <v-card>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-card-title v-text="$t('settings.changeFullName')" />
          </v-row>

          <v-text-field
            dense
            outlined
            :label="$t('settings.fullName')"
            class="px-2 hs-rounded-12"
            :rules="[v => v.trim().replace(/ +/g, ' ').split(' ').length > 1 && nameRegex.test(v) || $t('validation.fullName')]"
            v-model="fullName"
          />
          
          <v-text-field
            dense
            outlined
            :label="$t('settings.currentPwd')"
            class="px-2 hs-rounded-12"
            type="password"
            :rules="[v => !!v || $t('validation.enterPwd')]"
            v-model="password"
          />

          <v-divider />

          <v-card-actions>
            <v-spacer />

            <v-btn
              text
              class="hs-rounded-12"
              @click="clear"
              v-text="$t('cancel')"
            />

            <v-btn
              color="primary"
              class="hs-rounded-12"
              text
              @click="submit"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { nameRegex } from '@utils/regex'
import API from '@api'

export default {
  props: {
    dialogOn: {
      type: String,
      required: true
    },
    currentFullName: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      fullName: this.currentFullName,
      password: '',
      nameRegex
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {
    dialogOn (v) {
      this.dialog = v
    }
  },

  methods: {
    ...mapMutations({
      setUser: 'user'
    }),
    clear () {
      this.dialog = false
      this.fullName = this.currentFullName
      this.$emit('close')
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.dialog = false
        this.$emit('close')

        try {
          const fullName = this.fullName
          const id = this.user.id
          const email = this.user.email
          const password = this.password
          const params = {id, email, password}
          const userData = await API().put(`/user/${this.user.id}`, { fullName }, { params })
          this.setUser(userData)
        } catch (err) {
          if (err.response.data.errMsg)
           this.$emit('snackEvt', err.response.data.errMsg)
        }
      }
    }
  }
}
</script>