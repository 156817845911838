<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    @click:outside="$emit('close')"
  >
    <v-card rounded>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-card-title v-text="capitalizeChangePwd" />
          </v-row>

          <v-text-field
            v-if="!oauthOnly"
            dense
            outlined
            :label="$t('settings.currentPwd')"
            class="px-2 hs-rounded-12"
            type="password"
            v-model="currentPwd"
          />

          <v-text-field
            dense
            outlined
            :label="$t('settings.newPwd')"
            class="px-2 hs-rounded-12"
            type="password"
            :rules="[v => v.length >= 6 || $t('validation.invalidPwd')]"
            v-model="password"
          />

          <v-text-field
            dense
            outlined
            :label="$t('settings.confirmNewPwd')"
            class="px-2 hs-rounded-12"
            type="password"
            :rules="[v => v === password || $t('validation.pwdsDontMatch')]"
            v-model="passwordConfirm"
          />

          <v-divider />

          <v-card-actions>
            <v-spacer />

            <v-btn
              text
              v-text="$t('cancel')"
              @click="dialog = false"
            />

            <v-btn
              text
              color="primary"
              @click="submit"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { capitalize } from 'lodash'
import { mapGetters } from 'vuex'
import API from '@api'

export default {
  props: {
    dialogOn: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      currentPwd: '',
      password: '',
      passwordConfirm: ''
    }    
  },

  computed: {
    ...mapGetters({
      user: 'user'
    }),

    capitalizeChangePwd() {
      return capitalize(this.$t('settings.changePwd'))
    },

    oauthOnly() {
      return this.user.oauth ? this.user.oauth.isOnly : false
    }
  },

  watch: {
    dialogOn (v) {
      this.dialog = v
    }
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        
        this.dialog = false
        this.$emit('close')

        try {

          const id = this.user.id
          const email = this.user.email
          const password = this.password
          const params = {id, email, password: this.currentPwd}

          await API().put(`/user/${this.user.id}`, {password}, {params})

          this.$emit('snackEvt', 'pwdChanged')

        } catch (err) {

          if (err.response.data.errMsg) {

           this.$emit('snackEvt', err.response.data.errMsg)
          
          }

        }
      }
    }
  }
}
</script>