<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-form ref="form">
        <v-container>
          <v-row justify="center">
            <v-card-title v-text="$t('settings.changeEmail')" />
          </v-row>

          <v-text-field
            dense
            outlined
            :label="$t('settings.newEmail')"
            class="px-2 hs-rounded-12"
            :rules="[v => /.+@.+\..+/.test(v) || $t('validation.email')]"
            v-model="newEmail"
          />

          <v-text-field
            v-if="!user.oauth || !user.oauth.isOnly"
            dense
            outlined
            :label="$t('settings.currentPwd')"
            class="px-2 hs-rounded-12"
            type="password"
            v-model="password"
          />

          <v-divider />

          <v-card-actions>
            <v-spacer />

            <v-btn
              text
              class="hs-rounded-12"
              @click="clear"
              :disabled="loading"
              v-text="$t('cancel')"
            />

            <v-btn
              text
              class="hs-rounded-12"
              color="primary"
              :loading="loading"
              @click="submit"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@api'

export default {
  props: {
    dialogOn: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      loading: false,
      newEmail: this.email,
      password: '',
    }
  },

  computed: {
    ...mapGetters({
      user: 'user'
    })
  },

  watch: {
    dialogOn (v) {
      this.dialog = v
    }
  },

  methods: {
    clear () {
      this.dialog = false
      this.email = this.user.email
      this.$emit('close')
    },

    async submit () {
      if (this.$refs.form.validate()) {
        this.loading = true

        try {
          const fullName = this.fullName
          const email = this.user.email
          const newEmail = this.newEmail
          const password = this.password
          const user = this.user
          const data = {
            origin: 'STUDY_URL',
            newEmail,
            password,
            fullName,
            email,
            user
          }

          await API().post(`/user/validate/emailChange/${this.user.id}/${this.$i18n.locale}`, data)
          this.$emit('snackEvt', 'emailValidationSent')

        } catch (err) {

          if (err.response.data.errMsg) this.$emit('snackEvt', err.response.data.errMsg)

        } finally {
          this.dialog = false
          this.$emit('close')
          this.loading = false
        }
      }
    }
  }
}
</script>