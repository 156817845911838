<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-container>
        <v-row justify="center">
          <v-card-title>
            Deactivate account
          </v-card-title>
        </v-row>

        <v-container>
          If you deactivate your account, your profile will not be listed for other people on the site. You can reactivate your account at any time.
        </v-container>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            class="hs-rounded-12"
            v-text="$t('cancel')"
            @click="dialog = false"
          />

          <v-btn
            text
            class="hs-rounded-12"
            color="primary"
            @click="submit"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { capitalize } from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import API from '@api'

export default {
  props: {
    dialogOn: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      dialog: false,
      currentPwd: '',
      password: '',
      passwordConfirm: ''
    }    
  },

  computed: {
    ...mapGetters({
      user: 'user',
      profile: 'profile/id'
    }),

    capitalizeChangePwd() {
      return capitalize(this.$t('settings.changePwd'))
    }
  },

  watch: {
    dialogOn (v) {
      this.dialog = v
    }
  },

  methods: {

    ...mapMutations({
      SET_ACCOUNT_ACTIVATION: 'profile/isActive'  
    }),

    async submit() {
      
      this.dialog = false
      this.$emit('close')

      try {

        this.SET_ACCOUNT_ACTIVATION(false)

        await API().put(`/profile/${this.user.id}`, {
          isActive: false
        },
        {
          params: {
            id: this.profile
          }
        })

        this.$emit('snackEvt', 'accountDeactivated')

      } catch (err) {

        if (err.response.data.errMsg) {

          this.$emit('snackEvt', err.response.data.errMsg)
        
        }

      }
      
    }
  }
}
</script>